import { Editor, useEditorState } from "@tiptap/react";
import { memo, useCallback } from "react";

import deepEql from "fast-deep-equal";

import { Icon, SidebarMenu } from "utils/ui";

const MemoButton = memo(SidebarMenu.Button);

export const SidebarSpacerBlockMenu = ({ editor }: { editor: Editor }) => {
  const { height } = useEditorState({
    editor,
    selector: (ctx) => {
      return {
        height: parseInt(ctx.editor.getAttributes("spacerBlock")?.height || 0),
      };
    },
    equalityFn: deepEql,
  });

  const onWidthChange = useCallback(
    (value: number) => {
      editor.chain().focus(undefined, { scrollIntoView: false }).setSpacerBlockHeight(value).run();
    },
    [editor]
  );

  const onDelete = useCallback(() => {
    editor.chain().focus(undefined, { scrollIntoView: false }).deleteSelection().run();
  }, [editor]);

  return (
    <SidebarMenu.Wrapper title="Vertical Spacer">
      <SidebarMenu.Heading title="Height" value={height} />
      <SidebarMenu.SliderInput onChange={onWidthChange} value={height} />

      <SidebarMenu.Divider />

      <MemoButton tooltip="Remove spacer" onClick={onDelete}>
        <Icon name="Trash2" />
      </MemoButton>
    </SidebarMenu.Wrapper>
  );
};
