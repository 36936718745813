import deepEql from "fast-deep-equal";
import { Editor, useEditorState } from "@tiptap/react";
import { HorizontalLineBlock, ImageBlock, SpacerBlock } from "pages/tip-tap-editor/extensions";

export const useTextmenuContentTypes = (editor: Editor) =>
  useEditorState({
    editor,
    selector: (ctx) => {
      return {
        isImageBlock: ctx.editor.isActive(ImageBlock.name),
        isHorizontalLineBlock: ctx.editor.isActive(HorizontalLineBlock.name),
        isSpacerBlock: ctx.editor.isActive(SpacerBlock.name),
      };
    },
    equalityFn: deepEql,
  });
