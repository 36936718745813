import { ReactRenderer } from "@tiptap/react";
import tippy from "tippy.js";

import TokenMenuList from "./ItemList";
import GROUPS from "./groups";

export default {
  items: ({ query }: any) => {
    const filteredCommands = GROUPS.filter((item) => {
      const labelNormalized = item.label.toLowerCase().trim();
      const queryNormalized = query.toLowerCase().trim();
      if (item.aliases) {
        const aliases = item.aliases.map((alias) => alias.toLowerCase().trim());
        return labelNormalized.includes(queryNormalized) || aliases.includes(queryNormalized);
      }
      return labelNormalized.includes(queryNormalized);
    });

    return filteredCommands;
  },

  render: () => {
    let component: any;
    let popup: any;

    return {
      onStart: (props: any) => {
        component = new ReactRenderer(TokenMenuList, {
          props,
          editor: props.editor,
        });

        if (!props.clientRect) {
          return;
        }

        popup = tippy("body", {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: "manual",
          placement: "bottom-start",
        });
      },

      onUpdate(props: any) {
        component.updateProps(props);

        if (!props.clientRect) {
          return;
        }

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        });
      },

      onKeyDown(props: any) {
        if (props.event.key === "Escape") {
          popup[0].hide();

          return true;
        }

        return component.ref?.onKeyDown(props);
      },

      onExit() {
        popup[0].destroy();
        component.destroy();
      },
    };
  },
};
