import { LayoutWrapper } from "../layout";
import SideMenu from "./menu";
import Preview from "./preview";

function DefectListTemplate() {
  const menu = <SideMenu />;
  const preview = <Preview />;

  return <LayoutWrapper menu={menu} preview={preview} />;
}

export default DefectListTemplate;
