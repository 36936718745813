import "./ColorPickerButton.scss";
import { memo, useRef, useMemo } from "react";

import { OverlayPanel } from "primereact/overlaypanel";

import { Button } from "primereact/button";
import { Icon } from "..";
import { ColorPicker } from "pages/tip-tap-editor/components/panels/Colorpicker";

const MemoColorPicker = memo(ColorPicker);

export const ColorPickerButton = ({ currentColor, onChangeColor, onClearColor }: ColorPickerProps) => {
  const op = useRef<OverlayPanel>(null);

  const isDarkColor = useMemo(() => {
    if (!currentColor) return true;

    var c = currentColor.substring(1); // strip #
    var rgb = parseInt(c, 16); // convert rrggbb to decimal
    var r = (rgb >> 16) & 0xff; // extract red
    var g = (rgb >> 8) & 0xff; // extract green
    var b = (rgb >> 0) & 0xff; // extract blue

    var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

    return luma < 100;
  }, [currentColor]);

  const color = useMemo(() => {
    return currentColor || "#000000";
  }, [currentColor]);

  return (
    <>
      <Button
        className="color-picker-button"
        style={{ backgroundColor: color, borderColor: color }}
        rounded
        children={<Icon name="Palette" className={isDarkColor ? "text-white" : "text-black"} />}
        onClick={(e: React.MouseEvent) => {
          op.current?.toggle(e);
        }}
      ></Button>

      <OverlayPanel ref={op}>
        <MemoColorPicker color={color} onChange={onChangeColor} onClear={onClearColor} />
      </OverlayPanel>
    </>
  );
};

interface ColorPickerProps {
  currentColor: string | null | undefined;
  onChangeColor: (value: string) => void;
  onClearColor: () => void;
}

ColorPickerButton.displayName = "SidebarMenu.ColorPicker";
