import "./add-page-indicator.scss";
import { Plus } from "lucide-react";

export const AddPageIndicator = (props: _AddPageIndicatorProps) => {
  return (
    <div title="Add Section" className="add-page-indicator-wrapper" onClick={props.onClick}>
      <div className="add-page-indicator"> </div>
      <div className="add-page-indicator-icon">
        <Plus />
      </div>
    </div>
  );
};

interface _AddPageIndicatorProps {
  onClick: () => void;
}
