import { BubbleMenu as BaseBubbleMenu, Editor, useEditorState } from "@tiptap/react";
import { useCallback, useRef } from "react";
import { Instance, sticky } from "tippy.js";
import { v4 as uuidv4 } from "uuid";
import deepEql from "fast-deep-equal";

import { MenuProps } from "../types";
import { getRenderContainer } from "../../../utils";
import { ImageBlockTypes } from "pages/tip-tap-editor/extensions/ImageBlock";
import { Tooltip, Icon, Toolbar } from "utils/ui";

import { ImageBlockWidth } from "./components/ImageBlockWidth";

export const ImageBlockMenu = ({ editor, appendTo }: MenuProps): JSX.Element => {
  const menuRef = useRef<HTMLDivElement>(null);
  const tippyInstance = useRef<Instance | null>(null);

  const getReferenceClientRect = useCallback(() => {
    const renderContainer = getRenderContainer(editor, "node-imageBlock");
    const rect = renderContainer?.getBoundingClientRect() || new DOMRect(-1000, -1000, 0, 0);

    return rect;
  }, [editor]);

  const shouldShow = useCallback(() => {
    const isActive = editor.isActive("imageBlock");

    return isActive;
  }, [editor]);

  return (
    <BaseBubbleMenu
      editor={editor}
      pluginKey={`imageBlockMenu-${uuidv4()}`}
      shouldShow={shouldShow}
      updateDelay={0}
      tippyOptions={{
        offset: [0, 8],
        popperOptions: {
          modifiers: [{ name: "flip", enabled: false }],
        },
        getReferenceClientRect,
        onCreate: (instance: Instance) => {
          tippyInstance.current = instance;
        },
        appendTo: () => {
          return appendTo?.current;
        },
        plugins: [sticky],
        sticky: "popper",
      }}
    >
      <div ref={menuRef}>
        <ImageMenu editor={editor} />
      </div>
    </BaseBubbleMenu>
  );
};

export const ImageMenu = ({ editor }: { editor: Editor }) => {
  const { isImageCenter, isImageLeft, isImageRight, width, type } = useEditorState({
    editor,
    selector: (ctx) => {
      return {
        isImageLeft: ctx.editor.isActive("imageBlock", { align: "left" }),
        isImageCenter: ctx.editor.isActive("imageBlock", { align: "center" }),
        isImageRight: ctx.editor.isActive("imageBlock", { align: "right" }),
        width: parseInt(ctx.editor.getAttributes("imageBlock")?.width || 0),
        type: ctx.editor.getAttributes("imageBlock")?.type,
      };
    },
    equalityFn: deepEql,
  });

  const getTitle = useCallback(() => {
    if (type === ImageBlockTypes.LOGO) {
      return "Company Logo";
    }
    if (type === ImageBlockTypes.PROJECT_IMAGE) {
      return "Project Image";
    }
    return;
  }, [type]);

  const onAlignImageLeft = useCallback(() => {
    editor.chain().focus(undefined, { scrollIntoView: false }).setImageBlockAlign("left").run();
  }, [editor]);

  const onAlignImageCenter = useCallback(() => {
    editor.chain().focus(undefined, { scrollIntoView: false }).setImageBlockAlign("center").run();
  }, [editor]);

  const onAlignImageRight = useCallback(() => {
    editor.chain().focus(undefined, { scrollIntoView: false }).setImageBlockAlign("right").run();
  }, [editor]);

  const onWidthChange = useCallback(
    (value: number) => {
      editor.chain().focus(undefined, { scrollIntoView: false }).setImageBlockWidth(value).run();
    },
    [editor]
  );

  const onDelete = useCallback(() => {
    editor.chain().focus(undefined, { scrollIntoView: false }).deleteSelection().run();
  }, [editor]);

  return (
    <Toolbar.Wrapper
      // shouldShowContent={shouldShow()}
      //  ref={menuRef}
      title={getTitle()}
    >
      <Toolbar.Button tooltip="Align left" active={isImageLeft} onClick={onAlignImageLeft}>
        <Icon name="AlignHorizontalDistributeStart" />
      </Toolbar.Button>
      <Toolbar.Button tooltip="Align center" active={isImageCenter} onClick={onAlignImageCenter}>
        <Icon name="AlignHorizontalDistributeCenter" />
      </Toolbar.Button>
      <Toolbar.Button tooltip="Align right" active={isImageRight} onClick={onAlignImageRight}>
        <Icon name="AlignHorizontalDistributeEnd" />
      </Toolbar.Button>
      <Toolbar.Divider />
      <ImageBlockWidth onChange={onWidthChange} value={width} />

      <Toolbar.Divider />

      <Tooltip title="Remove image">
        <Toolbar.Button onClick={onDelete}>
          <Icon name="Trash2" />
        </Toolbar.Button>
      </Tooltip>
    </Toolbar.Wrapper>
  );
};
