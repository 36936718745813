import { memo, useCallback, useEffect, useState } from "react";
import { Icon } from "utils/ui";

export type ImageBlockWidthProps = {
  onChange: (value: number) => void;
  value: number;
};

export const HorizontalLineBlockHeight = memo(({ onChange, value }: ImageBlockWidthProps) => {
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      try {
        const nextValue = parseInt(e.target.value);

        // slider bug causing slider to jump to 50, don't set 50 unless its close to last value
        if (nextValue === 51) {
          return;
        }

        if (currentValue !== nextValue) {
          onChange(nextValue);
          setCurrentValue(nextValue);
        }
      } catch (ex) {
        // console.error(ex);
      }
    },
    [onChange, currentValue]
  );

  return (
    <div className="flex items-center gap-2 pr-2">
      <div className="text-neutral-500">
        <Icon name="UnfoldVertical" />
      </div>
      <input
        className="h-2 bg-neutral-200 border-0 rounded appearance-none fill-neutral-300"
        type="range"
        min="1"
        max="51"
        step="1"
        onChange={handleChange}
        value={currentValue}
      />
      <span className="text-xs font-semibold text-neutral-500 select-none">{value}px</span>
    </div>
  );
});

HorizontalLineBlockHeight.displayName = "HorizontalLineBlockHeight";
