export enum SettingsKey {
  // COVER PAGE
  ReportShowCompanyName = "reportShowCompanyName",
  ReportShowProjectImage = "reportShowProjectImage",
  ReportShowProjectRef = "reportShowProjectRef",
  ReportShowAddress = "reportShowAddress",
  ReportShowClient = "reportShowClient",
  ReportShowPreparedBy = "reportShowPreparedBy",
  ReportShowNotes = "reportShowNotes",
  ReportShowSignatures = "reportShowSignatures",
  //
  ContentsPageTitle = "contentsPageTitle",
  // DEFECT LIST BLOCK OPTIONS
  ReportShowDefectOrientation = "reportShowDefectOrientation",
  ReportShowDefectNumber = "reportShowDefectNumber",
  ReportShowDefectStatus = "reportShowDefectStatus",
  ReportShowDefectArea = "reportShowDefectArea",
  ReportShowDefectElement = "reportShowDefectElement",
  ReportShowDefectIssue = "reportShowDefectIssue",
  ReportShowDefectAssignment = "reportShowDefectAssignment",
  ReportShowDefectComments = "reportShowDefectComments",
  ReportShowDefectActivity = "reportShowDefectActivity",
  ReportShowDefectPriority = "reportShowDefectPriority",
  ReportShowDefectDuedate = "reportShowDefectDuedate",
  ReportShowDefectPhotoMeta = "reportShowPhotoMetadata",
}
