import { Group } from "./types";

export const GROUPS: Group[] = [
  {
    name: "format",
    title: "Format",
    commands: [
      {
        name: "heading1",
        label: "Heading 1",
        iconName: "Heading1",
        description: "High priority section title",
        aliases: ["h1"],
        action: (editor) => {
          editor.chain().focus().setHeading({ level: 1 }).run();
        },
      },
      {
        name: "heading2",
        label: "Heading 2",
        iconName: "Heading2",
        description: "Medium priority section title",
        aliases: ["h2"],
        action: (editor) => {
          editor.chain().focus().setHeading({ level: 2 }).run();
        },
      },
      {
        name: "heading3",
        label: "Heading 3",
        iconName: "Heading3",
        description: "Low priority section title",
        aliases: ["h3"],
        action: (editor) => {
          editor.chain().focus().setHeading({ level: 3 }).run();
        },
      },
      {
        name: "bulletList",
        label: "Bullet List",
        iconName: "List",
        description: "Unordered list of items",
        aliases: ["ul"],
        action: (editor) => {
          editor.chain().focus().toggleBulletList().run();
        },
      },
      {
        name: "numberedList",
        label: "Numbered List",
        iconName: "ListOrdered",
        description: "Ordered list of items",
        aliases: ["ol"],
        action: (editor) => {
          editor.chain().focus().toggleOrderedList().run();
        },
      },
      // {
      //   name: "taskList",
      //   label: "Task List",
      //   iconName: "ListTodo",
      //   description: "Task list with todo items",
      //   aliases: ["todo"],
      //   action: (editor) => {
      //     editor.chain().focus().toggleTaskList().run();
      //   },
      // },
    ],
  },
  {
    name: "insert",
    title: "Insert",
    commands: [
      // {
      //   name: "table",
      //   label: "Table",
      //   iconName: "Table",
      //   description: "Insert a table",
      //   shouldBeHidden: (editor) => editor.isActive("columns"),
      //   action: (editor) => {
      //     editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: false }).run();
      //   },
      // },
      {
        name: "columns",
        label: "Columns",
        iconName: "Columns2",
        description: "Add two column content",
        aliases: ["cols"],
        shouldBeHidden: (editor) => editor.isActive("columns"),
        action: (editor) => {
          editor
            .chain()
            .focus()
            .setColumns()
            .focus(editor.state.selection.head - 1)
            .run();
        },
      },
      {
        name: "horizontalLineBlock",
        label: "Horizontal Line",
        iconName: "Minus",
        description: "Insert a styled horizontal line",
        shouldBeHidden: (editor) => editor.isActive("horizontal-line"),
        action: (editor) => {
          editor.chain().focus().setHorizontalLine().run();
        },
      },
      {
        name: "pageBreakBlock",
        label: "Page Break",
        iconName: "PenLine",
        description: "Insert a page break",
        shouldBeHidden: (editor) => editor.isActive("pageBreak"),
        action: (editor) => {
          editor.chain().focus().setPageBreakBlock().run();
        },
      },
      {
        name: "signatureBlock",
        label: "Signature Block",
        iconName: "PenLine",
        description: "Insert a signature line",
        shouldBeHidden: (editor) => editor.isActive("signature"),
        action: (editor) => {
          editor.chain().focus().setSignatureBlock().run();
        },
      },
      {
        name: "spacerBlock",
        label: "Vertical Spacer",
        iconName: "AlignVerticalSpaceAround",
        description: "Insert a vertical spacer",
        aliases: ["spacer", "gap"],
        action: (editor) => {
          editor.chain().focus().setSpacerBlock().run();
        },
      },
    ],
  },
  {
    name: "media",
    title: "Media",
    commands: [
      {
        name: "logo",
        label: "Company Logo",
        iconName: "Image",
        description: "Insert the company logo uploaded via Company Info page",
        aliases: ["img", "logo", "picture", "image", "pic", "photo", "file"],
        action: (editor) => {
          editor.chain().focus().setLogoImageBlock().run();
        },
      },
      {
        name: "projectImage",
        label: "Project Image",
        iconName: "Image",
        description: "Insert the project image uploaded via Project Info page",
        aliases: ["img", "project", "picture", "image", "pic", "photo", "file"],
        action: (editor) => {
          editor.chain().focus().setProjectImageBlock().run();
        },
      },
      {
        name: "image",
        label: "Image",
        iconName: "Image",
        description: "Insert an image",
        aliases: ["img", "picture", "image", "pic", "photo", "file"],
        action: (editor) => {
          editor.chain().focus().setImageBlock({ src: "" }).run();
        },
      },
    ],
  },
];

export default GROUPS;
