import "./tiptap.scss";

import { Content, Editor, EditorContent } from "@tiptap/react";
import { useEffect } from "react";

import { useMiniBlockEditor } from "./hooks/useMiniBlockEditor";
import { MiniEditorTextMenu } from "./components/menus/TextMenu";

const MiniTiptap = ({
  initialData,
  onChange,
}: {
  toolbarHeight: number;
  initialData?: Content;
  onChange: (json: any) => void;
}) => {
  // const menuContainerRef = useRef(null);

  const { editor } = useMiniBlockEditor({
    onChange,
  });

  useEffect(() => {
    const content: Content = initialData ?? [];

    if (content.length === 0 && !editor.isEmpty) {
      editor.commands.clearContent();
    } else if (content.length > 0) {
      editor.commands.setContent(content);
      editor.commands.blur();
    }
  }, [initialData, editor.isEmpty, editor.commands]);

  if (!editor) {
    return null;
  }

  try {
    return (
      <>
        <div className="mb-2">
          <MiniEditorTextMenu editor={editor} />
        </div>

        <div
          className="mini-tiptap-wrapper"
          style={{
            height: "200px",
            overflow: "auto",
          }}
        >
          <MiniTipTapEditor editor={editor} />
        </div>
      </>
    );
  } catch (ex) {
    return <div>Error</div>;
  }
};

const MiniTipTapEditor = ({ editor }: _MiniTipTapEditorProps) => {
  return <EditorContent editor={editor} autoFocus={false} />;
};

interface _MiniTipTapEditorProps {
  editor: Editor;
}

export default MiniTiptap;
