import { useCallback } from "react";

import { InputSwitch } from "primereact/inputswitch";

import { useAppDispatch, setOption } from "utils/store";
import { SettingsKey, TDefectListOption, TDefectListOptions } from "models";

interface _OptionsInputSwitchProps {
  settingsKey: SettingsKey;
  options: TDefectListOptions;
  disabled: boolean;
  label: string;
  onChange: () => void;
}

const OptionsInputSwitch = ({ settingsKey, options, disabled, label, onChange }: _OptionsInputSwitchProps) => {
  const dispatch = useAppDispatch();

  const toggleSetting = useCallback(
    ({ value }: any) => {
      let option: TDefectListOption | undefined = options[settingsKey];
      if (!option) {
        // option is missing for whatever reason, add it
        option = { key: settingsKey, datatype: "boolean", value };
      }
      dispatch(setOption({ key: settingsKey, option: { ...option, value } }));
      onChange();
    },

    [dispatch, onChange, settingsKey, options]
  );

  return (
    <div className="flex flex-row justify-between items-center">
      <div>{label}</div>
      <InputSwitch
        className="p-primary"
        checked={options[settingsKey]?.value === true}
        disabled={disabled}
        onChange={(e) => toggleSetting(e)}
      />
    </div>
  );
};

export default OptionsInputSwitch;
