import { Editor, useEditorState } from "@tiptap/react";
import { memo, useCallback } from "react";

import deepEql from "fast-deep-equal";

import { Icon, SidebarMenu } from "utils/ui";

import { ButtonGroup } from "primereact/buttongroup";

const MemoButton = memo(SidebarMenu.Button);

export const SidebarHorizontalLineMenu = ({ editor }: { editor: Editor }) => {
  const { isImageCenter, isImageLeft, isImageRight, width, height, currentColor } = useEditorState({
    editor,
    selector: (ctx) => {
      return {
        isImageLeft: ctx.editor.isActive("horizontalLine", { alignment: "left" }),
        isImageCenter: ctx.editor.isActive("horizontalLine", { alignment: "center" }),
        isImageRight: ctx.editor.isActive("horizontalLine", { alignment: "right" }),
        width: parseInt(ctx.editor.getAttributes("horizontalLine")?.width || 0),
        height: parseInt(ctx.editor.getAttributes("horizontalLine")?.height || 0),
        currentColor: ctx.editor.getAttributes("horizontalLine")?.color || undefined,
      };
    },
    equalityFn: deepEql,
  });

  const onAlignLeft = useCallback(() => {
    editor.chain().focus(undefined, { scrollIntoView: false }).setHorizontalLineAlign("left").run();
  }, [editor]);

  const onAlignCenter = useCallback(() => {
    editor.chain().focus(undefined, { scrollIntoView: false }).setHorizontalLineAlign("center").run();
  }, [editor]);

  const onAlignRight = useCallback(() => {
    editor.chain().focus(undefined, { scrollIntoView: false }).setHorizontalLineAlign("right").run();
  }, [editor]);

  const onWidthChange = useCallback(
    (value: number) => {
      editor.chain().focus(undefined, { scrollIntoView: false }).setHorizontalLineWidth(value).run();
    },
    [editor]
  );

  const onHeightChange = useCallback(
    (value: number) => {
      editor.chain().focus(undefined, { scrollIntoView: false }).setHorizontalLineHeight(value).run();
    },
    [editor]
  );

  const onChangeColor = useCallback((color: string) => editor.chain().setHorizontalLineColor(color).run(), [editor]);

  const onDelete = useCallback(() => {
    editor.chain().focus(undefined, { scrollIntoView: false }).deleteSelection().run();
  }, [editor]);

  return (
    <SidebarMenu.Wrapper title={"Horizontal Line"}>
      <SidebarMenu.Heading title="Alignment" />

      <ButtonGroup>
        <MemoButton tooltip="Align left" active={isImageLeft} onClick={onAlignLeft}>
          <Icon name="AlignHorizontalDistributeStart" />
        </MemoButton>
        <MemoButton tooltip="Align center" active={isImageCenter} onClick={onAlignCenter}>
          <Icon name="AlignHorizontalDistributeCenter" />
        </MemoButton>
        <MemoButton tooltip="Align right" active={isImageRight} onClick={onAlignRight}>
          <Icon name="AlignHorizontalDistributeEnd" />
        </MemoButton>
      </ButtonGroup>

      <SidebarMenu.Divider />
      <SidebarMenu.Heading title="Width" value={`${width} %`} />
      <SidebarMenu.SliderInput onChange={onWidthChange} value={width} />

      <SidebarMenu.Divider />
      <SidebarMenu.Heading title="Height" value={height} />
      <SidebarMenu.SliderInput onChange={onHeightChange} value={height} minValue={1} maxValue={50} />

      <SidebarMenu.Divider />
      <SidebarMenu.Heading title="Color" />
      <SidebarMenu.ColorPicker
        currentColor={currentColor}
        onChangeColor={onChangeColor}
        onClearColor={() => {
          onChangeColor("#000000");
        }}
      />

      <SidebarMenu.Divider />
      <SidebarMenu.Heading title="Remove" />
      <MemoButton tooltip="Remove line" onClick={onDelete}>
        <Icon name="Trash2" />
      </MemoButton>
    </SidebarMenu.Wrapper>
  );
};
