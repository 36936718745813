import { TemplatePageType } from "models";

export const getPageName = (pageType: TemplatePageType) => {
  switch (pageType) {
    case TemplatePageType.coverPage:
      return "Cover Page";
    case TemplatePageType.contentsPage:
      return "Contents Page";
    case TemplatePageType.defectList:
      return "Defect Lists";
    case TemplatePageType.customPage:
      return "Custom Section";
  }
};
