import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

import { type RootState } from "./hooks";
import {
  type TDefectListOption,
  blankDefectListOptions,
  defaultCoverPage,
  defaultPageFooter,
  defaultPageHeader,
  SettingsKey,
  TDefectListOptions,
  TemplatePageType,
  TSectionPage,
  TTemplateContent,
} from "models";
import { Content } from "@tiptap/react";

/** Define a type for the slice state */
type _TemplateBuilderState = {
  documentId: string | undefined;
  templateName: string;
  logoUrl: string;
  storageToken: string;
  pages: TSectionPage[];
  selectedPageIndex: number;
  defaultOptions: TDefectListOptions;
  settings: {
    reportHeader: Content;
    reportFooter: Content;
  };
  /** Defines the initial editor text. */
  editorInit: Content | undefined;
  headerEditorInit: Content | undefined;
  footerEditorInit: Content | undefined;
  loading: boolean;
  isSaving: boolean;
  showSettings: boolean;
  version: number;
};

/** Defines the initial state using that type */
const initialState: _TemplateBuilderState = {
  documentId: undefined,
  templateName: "Report Template",
  logoUrl: "",
  storageToken: "",
  selectedPageIndex: 0,
  pages: [],
  defaultOptions: blankDefectListOptions,
  settings: {
    reportHeader: [],
    reportFooter: [],
  },
  editorInit: [],
  headerEditorInit: [],
  footerEditorInit: [],
  loading: false,
  isSaving: false,
  showSettings: false,
  version: 1,
};

export const templateBuilderSlice = createSlice({
  name: "template-builder-data",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    loadTemplate: (
      state,
      action: PayloadAction<{ templateName: string; data?: TTemplateContent; logoUrl?: string; storageToken: string }>
    ) => {
      let templatePages: TSectionPage[] = [];

      if (state.templateName !== action.payload.templateName) {
        state.templateName = action.payload.templateName;
      }

      if (action.payload.logoUrl) {
        state.logoUrl = action.payload.logoUrl;
      }

      if (action.payload.storageToken) {
        state.storageToken = action.payload.storageToken;
      }

      if (action.payload.data) {
        const { defaultOptions, pages, settings, id } = action.payload.data;
        state.documentId = id;

        if (pages) {
          templatePages = pages;
        }

        if (defaultOptions) {
          state.defaultOptions = defaultOptions;
        }

        if (settings.reportHeader) {
          state.headerEditorInit = settings.reportHeader;
          state.settings.reportHeader = settings.reportHeader;
        }

        if (settings.reportFooter) {
          state.footerEditorInit = settings.reportFooter;
          state.settings.reportFooter = settings.reportFooter;
        }
      }

      if (templatePages.length === 0) {
        // new
        state.settings.reportHeader = defaultPageHeader;
        state.headerEditorInit = defaultPageHeader;
        state.settings.reportFooter = defaultPageFooter;
        state.footerEditorInit = defaultPageFooter;
      }

      // check templatePages has a cover page, contents page and a defect list page type
      if (!templatePages.find((o) => o.type === TemplatePageType.coverPage)) {
        templatePages.unshift({
          id: uuidv4(),
          type: TemplatePageType.coverPage,
          content: defaultCoverPage,
        });
      }

      if (!templatePages.find((o) => o.type === TemplatePageType.contentsPage)) {
        templatePages.push({ id: uuidv4(), type: TemplatePageType.contentsPage, content: [] });
      }

      if (!templatePages.find((o) => o.type === TemplatePageType.defectList)) {
        templatePages.push({ id: uuidv4(), type: TemplatePageType.defectList, content: [] });
      }

      if (templatePages.length > 0) {
        state.pages = templatePages;
        state.selectedPageIndex = 0;
        state.editorInit = templatePages[0].content;
      }
    },
    reorderPage: (state, action: PayloadAction<{ selectedPageIndex: number; pages: TSectionPage[] }>) => {
      state.selectedPageIndex = action.payload.selectedPageIndex;
      state.pages = action.payload.pages;
    },
    movePage: (state, action: PayloadAction<{ from: number; to: number }>) => {
      const { from, to } = action.payload;
      if (to < 1 || to > state.pages.length) return;

      const reorderedList = [...state.pages];

      var element = reorderedList[from];
      reorderedList.splice(from, 1);
      reorderedList.splice(to, 0, element);

      // Validate contents page is before defect list page
      const indexOfCover = reorderedList.findIndex((o) => o.type === TemplatePageType.coverPage);
      const indexOfContents = reorderedList.findIndex((o) => o.type === TemplatePageType.contentsPage);
      const indexOfDefectList = reorderedList.findIndex((o) => o.type === TemplatePageType.defectList);

      if (indexOfCover !== 0 || indexOfContents > indexOfDefectList) return;

      state.pages = reorderedList;

      state.showSettings = false;

      if (to >= 0 && to < state.pages.length) {
        state.selectedPageIndex = to;
        if (
          state.pages[to].type === TemplatePageType.customPage ||
          state.pages[to].type === TemplatePageType.coverPage
        ) {
          state.editorInit = [...(state.pages[to].content as any[])];
        } else {
          state.editorInit = [];
        }
      }
    },
    selectPage(state, action: PayloadAction<number>) {
      const newIndex = action.payload;

      if (state.pages[newIndex].type === TemplatePageType.contentsPage) {
        // state.selectedPageIndex = -1;
        // state.showSettings = true;
        return;
      }

      state.showSettings = false;

      if (newIndex >= 0 && newIndex < state.pages.length) {
        state.selectedPageIndex = newIndex;
        if (
          state.pages[newIndex].type === TemplatePageType.customPage ||
          state.pages[newIndex].type === TemplatePageType.coverPage
        ) {
          state.editorInit = [...(state.pages[newIndex].content as any[])];
        } else {
          state.editorInit = [];
        }
      }
    },
    selectSettingsPage(state) {
      state.selectedPageIndex = -1;
      state.showSettings = true;
    },
    addPage(state, actions: PayloadAction<number | null>) {
      let index = actions.payload;

      const pages = [...state.pages];
      const newPage: TSectionPage = {
        type: TemplatePageType.customPage,
        id: uuidv4(),
        content: [],
      };

      if (index !== null) {
        // new page position is +1 of index
        index++;
        // custom page cannot be in front of cover sheet
        if (index < 1) index = 1;
        pages.splice(index, 0, newPage);
      } else {
        pages.push(newPage);
        index = pages.length - 1;
      }

      state.pages = pages;
      state.selectedPageIndex = Math.min(Math.max(0, index), pages.length);
      state.editorInit = [];
    },
    deletePage(state, action: PayloadAction<number>) {
      const index = action.payload;
      if (state.pages[index]) {
        const pages = [...state.pages];
        pages.splice(index, 1);
        const newPageSelection = Math.min(Math.max(0, index - 1), pages.length - 1);
        // update view
        state.pages = pages;
        state.selectedPageIndex = newPageSelection;
      }
    },
    onContentChange(state, action: PayloadAction<{ type: "doc"; content: Content }>) {
      if (state.pages[state.selectedPageIndex]) {
        const doc = action.payload;
        (state.pages[state.selectedPageIndex] as any).content = doc.content ?? [];
      }
    },
    setOption(state, action: PayloadAction<{ key: SettingsKey; option: TDefectListOption }>) {
      // update or create new option
      state.defaultOptions[action.payload.key] = action.payload.option;
    },
    setHeaderContent(state, action: PayloadAction<{ type: "doc"; content: Content }>) {
      state.settings.reportHeader = action.payload.content;
    },
    setFooterContent(state, action: PayloadAction<{ type: "doc"; content: Content }>) {
      state.settings.reportFooter = action.payload.content;
    },
    setTemplateTitle(state, action: PayloadAction<string>) {
      state.templateName = action.payload;
    },
    setDocumentId(state, action: PayloadAction<string>) {
      if (state.documentId !== action.payload) {
        state.documentId = action.payload;
      }
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setSaving(state, action: PayloadAction<boolean>) {
      state.isSaving = action.payload;
    },
  },
});

export const {
  loadTemplate,
  selectPage,
  deletePage,
  reorderPage,
  addPage,
  onContentChange,
  setOption,
  setTemplateTitle,
  selectSettingsPage,
  setDocumentId,
  setLoading,
  setFooterContent,
  setHeaderContent,
  movePage,
  setSaving,
} = templateBuilderSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectCount = (state: RootState) => state.templateBuilderSlice.selectedPageIndex;

export default templateBuilderSlice.reducer;
