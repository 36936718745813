import { useCallback, useMemo, useRef } from "react";
import { NodeViewProps, NodeViewWrapper } from "@tiptap/react";
import { Image } from "lucide-react";

import { cn } from "utils/helpers";
import { useAppSelector } from "utils/store";

import { ImageBlockTypes } from "pages/tip-tap-editor/extensions/ImageBlock";

export const ImageBlockView = (props: NodeViewProps) => {
  const logoUrl = useAppSelector((state) => state.templateBuilderSlice.logoUrl);
  const storageToken = useAppSelector((state) => state.templateBuilderSlice.storageToken);

  const { editor, getPos, node } = props;
  const imageWrapperRef = useRef<HTMLDivElement>(null);
  const { src, type } = node.attrs;

  const wrapperClassName = cn(
    node.attrs.align === "left" ? "ml-0" : "ml-auto",
    node.attrs.align === "right" ? "mr-0" : "mr-auto",
    node.attrs.align === "center" && "mx-auto"
  );

  const imgSrc = useMemo(() => {
    if (type === ImageBlockTypes.LOGO) {
      return logoUrl ?? src;
    }
    const result = src ? `${src}?${storageToken}` : "";
    return result;
  }, [logoUrl, storageToken, src, type]);

  const onClick = useCallback(() => {
    editor.commands.setNodeSelection(getPos());
  }, [getPos, editor.commands]);

  return (
    <NodeViewWrapper>
      <div className={wrapperClassName} style={{ width: node.attrs.width, background: "var(--surface-100)" }}>
        <div contentEditable={false} ref={imageWrapperRef}>
          {imgSrc && <img className="block" src={imgSrc} alt="" onClick={onClick} />}
          {!imgSrc && (
            <div className="p-6 flex flex-col items-center justify-center" style={{ color: "var(--surface-600)" }}>
              <div>
                <Image size={64} strokeWidth={1} />
              </div>
              <p>Select an image.</p>
            </div>
          )}
        </div>
      </div>
    </NodeViewWrapper>
  );
};

export default ImageBlockView;
