import { Editor } from "@tiptap/react";
import { memo } from "react";
import * as Popover from "@radix-ui/react-popover";

import { useTextmenuCommands } from "./hooks/useTextmenuCommands";
import { useTextmenuStates } from "./hooks/useTextmenuStates";

import { ColorPicker, FieldTokenPanel } from "../../panels";
import { Icon, Toolbar, Surface } from "utils/ui";
import { EditLinkPopover } from "./components/EditLinkPopover";

// We memorize the button so each button is not rerendered
// on every editor state change
const MemoButton = memo(Toolbar.Button);
const MemoColorPicker = memo(ColorPicker);

export const MiniEditorTextMenu = ({ editor }: { editor: Editor }) => {
  const commands = useTextmenuCommands(editor);
  const states = useTextmenuStates(editor);

  return (
    <Toolbar.Wrapper className="surface-section">
      <MemoButton tooltip="Bold" tooltipShortcut={["Mod", "B"]} onClick={commands.onBold} active={states.isBold}>
        <Icon name="Bold" />
      </MemoButton>
      <MemoButton tooltip="Italic" tooltipShortcut={["Mod", "I"]} onClick={commands.onItalic} active={states.isItalic}>
        <Icon name="Italic" />
      </MemoButton>
      <MemoButton
        tooltip="Underline"
        tooltipShortcut={["Mod", "U"]}
        onClick={commands.onUnderline}
        active={states.isUnderline}
      >
        <Icon name="Underline" />
      </MemoButton>

      <Toolbar.Divider />

      <MemoButton
        tooltip="Align left"
        tooltipShortcut={["Shift", "Mod", "L"]}
        onClick={commands.onAlignLeft}
        active={states.isAlignLeft}
      >
        <Icon name="AlignLeft" />
      </MemoButton>
      <MemoButton
        tooltip="Align center"
        tooltipShortcut={["Shift", "Mod", "E"]}
        onClick={commands.onAlignCenter}
        active={states.isAlignCenter}
      >
        <Icon name="AlignCenter" />
      </MemoButton>
      <MemoButton
        tooltip="Align right"
        tooltipShortcut={["Shift", "Mod", "R"]}
        onClick={commands.onAlignRight}
        active={states.isAlignRight}
      >
        <Icon name="AlignRight" />
      </MemoButton>

      <Toolbar.Divider />

      <EditLinkPopover onSetLink={commands.onLink} />

      <Popover.Root>
        <Popover.Trigger asChild>
          <MemoButton active={!!states.currentColor} tooltip="Text color">
            <Icon name="Palette" />
          </MemoButton>
        </Popover.Trigger>
        <Popover.Content style={{ zIndex: "9999" }} side="top" sideOffset={8} asChild>
          <Surface className="p-1">
            <MemoColorPicker
              color={states.currentColor}
              onChange={commands.onChangeColor}
              onClear={commands.onClearColor}
            />
          </Surface>
        </Popover.Content>
      </Popover.Root>

      <Toolbar.Divider />

      <Popover.Root>
        <Popover.Trigger asChild>
          <MemoButton tooltip="Insert a field" tooltipShortcut={["@"]} active={false}>
            Insert Field
          </MemoButton>
        </Popover.Trigger>
        <Popover.Content style={{ zIndex: "9999" }} side="bottom" sideOffset={8} asChild>
          <Surface className="p-1">
            <FieldTokenPanel editor={editor} />
          </Surface>
        </Popover.Content>
      </Popover.Root>
    </Toolbar.Wrapper>
  );
};
