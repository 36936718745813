import "./grabbable-button.scss";
import { useCallback, useEffect, useRef, useState } from "react";

import { draggable, dropTargetForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { setCustomNativeDragPreview } from "@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview";
import { pointerOutsideOfPreview } from "@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import invariant from "tiny-invariant";
import { createPortal } from "react-dom";
import {
  attachClosestEdge,
  type Edge,
  extractClosestEdge,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";

import { getPageName } from "./hooks";
import { AddPageIndicator, DragHandle, DragPreview, DropIndicator } from "./components";

import { TSectionPage, TemplatePageType, getTemplatePageData, isTemplatePageData } from "models";
import { Icon } from "utils/ui";
import { cn } from "utils/helpers";
import { Button } from "primereact/button";

type _GrabbableState =
  | {
      type: "idle";
    }
  | {
      type: "preview";
      container: HTMLElement;
    }
  | {
      type: "is-dragging";
    }
  | {
      type: "is-dragging-over";
      closestEdge: Edge | null;
    };

const idle: _GrabbableState = { type: "idle" };

export const GrabbableButton = ({
  pageElement,
  selected,
  onPageSelect,
  onAddPage,
  onPageDelete,
  onMovePage,
}: _GrabbableButtonProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [state, setState] = useState<_GrabbableState>(idle);

  useEffect(() => {
    const element = ref.current;

    // invariant - https://github.com/alexreardon/pdnd-react-tailwind/blob/main/src/task.tsx
    invariant(element, "element should not be null");

    return combine(
      draggable({
        element,
        getInitialData() {
          return getTemplatePageData(pageElement);
        },
        onGenerateDragPreview({ nativeSetDragImage }) {
          setCustomNativeDragPreview({
            nativeSetDragImage,
            getOffset: pointerOutsideOfPreview({
              x: "16px",
              y: "8px",
            }),
            render({ container }) {
              setState({ type: "preview", container });
            },
          });
        },
        onDragStart() {
          setState({ type: "is-dragging" });
        },
        onDrop() {
          setState(idle);
        },
      }),
      dropTargetForElements({
        element,
        canDrop({ source }) {
          // not allowing dropping on yourself
          if (source.element === element) {
            return false;
          }

          if (source.data.taskId === "task-0") {
            return false;
          }

          // only allowing tasks to be dropped on me
          return isTemplatePageData(source.data);
        },
        getData({ input }) {
          const data = getTemplatePageData(pageElement);
          return attachClosestEdge(data, {
            element,
            input,
            allowedEdges: ["top", "bottom"],
          });
        },
        getIsSticky() {
          return true;
        },
        onDragEnter({ self }) {
          const closestEdge = extractClosestEdge(self.data);
          setState({ type: "is-dragging-over", closestEdge });
        },
        onDrag({ self }) {
          const closestEdge = extractClosestEdge(self.data);

          // Only need to update react state if nothing has changed.
          // Prevents re-rendering.
          setState((current) => {
            if (current.type === "is-dragging-over" && current.closestEdge === closestEdge) {
              return current;
            }
            return { type: "is-dragging-over", closestEdge };
          });
        },
        onDragLeave() {
          setState(idle);
        },
        onDrop() {
          setState(idle);
        },
      })
    );
  }, [pageElement]);

  /**
   * Select the page when not dragging
   */
  const onSelect = useCallback((): void => {
    if (state.type === "idle") {
      onPageSelect();
    }
  }, [state.type, onPageSelect]);

  const isCoverPage = pageElement.type === TemplatePageType.coverPage;
  const isCustomPage = pageElement.type === TemplatePageType.customPage;
  const isContentsPage = pageElement.type === TemplatePageType.contentsPage;

  return (
    <>
      <div>
        <div
          // Adding data-attribute as a way to query for this for our post drop flash
          data-task-id={pageElement.id}
          ref={ref}
          className={cn([
            "grabbable-button",
            selected ? "page-element-selected" : "",
            isContentsPage ? "cursor-not-allowed" : "cursor-pointer",
          ])}
          onClick={onSelect}
        >
          {isCoverPage && <div ref={ref}></div>}
          {!isCoverPage && <DragHandle ref={ref} disabled={isCoverPage} onMovePage={onMovePage} />}

          {/** ACTIONS */}
          <div className="relative grabbable-page-item-actions">
            {isCustomPage ? (
              <Button
                title="Delete custom section"
                className="grabbable-delete-btn"
                severity="danger"
                size="small"
                onClick={(ev) => {
                  ev.stopPropagation();
                  onPageDelete(ev);
                }}
              >
                <Icon name="Trash2" size={18} strokeWidth={2} />
              </Button>
            ) : null}
          </div>

          {/** CONTENT */}
          {pageElement.type === TemplatePageType.coverPage ? <Icon name="House" size={20} strokeWidth={2} /> : null}
          {pageElement.type === TemplatePageType.contentsPage ? (
            <Icon name="TableOfContents" size={20} strokeWidth={2} />
          ) : null}
          {pageElement.type === TemplatePageType.defectList ? (
            <Icon name="ListChecks" size={20} strokeWidth={2} />
          ) : null}
          {pageElement.type === TemplatePageType.customPage ? (
            <Icon name="LetterText" size={20} strokeWidth={2} />
          ) : null}

          <span className="grabbable-label mt-2">{getPageName(pageElement.type)}</span>
        </div>

        {state.type === "is-dragging-over" && state.closestEdge ? (
          <DropIndicator />
        ) : (
          <AddPageIndicator onClick={onAddPage} />
        )}
      </div>
      {state.type === "preview" ? createPortal(<DragPreview pageElement={pageElement} />, state.container) : null}
    </>
  );
};

interface _GrabbableButtonProps {
  selected: boolean;
  pageElement: TSectionPage;
  onPageSelect: () => void;
  onAddPage: () => void;
  onPageDelete: (event: any) => void;
  onMovePage: (direction: 1 | -1) => void;
}
