import { TokenCommand } from "./types";

export const FIELD_TOKENS: TokenCommand[] = [
  {
    id: "company",
    label: "Company Name",
    description: "Your company id as per the company info",
    aliases: ["company", "business"],
  },
  {
    id: "projectName",
    label: "Project Name",
    description: "The id of the project",
    aliases: ["project", "id", "title"],
  },
  {
    id: "projectRef",
    label: "Project Ref",
    description: "The reference number of the project",
    aliases: ["ref", "reference", "number", "id"],
  },
  {
    id: "createDate",
    label: "Create Date",
    description: "The selected date when generating a report",
    aliases: ["date", "time", "timestamp"],
  },
  {
    id: "client",
    label: "Project Client",
    description: "The client field recorded in the project details",
    aliases: ["client", "owner"],
  },
  {
    id: "projectAddress",
    label: "Project Address",
    description: "The address of the project",
    aliases: ["address", "location"],
  },
  {
    id: "userName",
    label: "Created By User",
    description: "The id of the user who created the report",
    aliases: ["user", "author", "id", "inspector"],
  },
  {
    id: "userPosition",
    label: "Created By User Position",
    description: "The position of the user who created the report",
    aliases: ["position", "role", "job", "title"],
  },

  {
    id: "reportNotes",
    label: "Report Notes",
    description: "Report summary added while generating the report",
    aliases: ["notes", "summary", "conclusion", "comments", "description"],
  },
];

export default FIELD_TOKENS;
