import "./tiptap.scss";

import { Content, Editor, EditorContent } from "@tiptap/react";
import { useEffect, useRef } from "react";

import { LinkMenu } from "./components/menus";

import { useBlockEditor } from "./hooks/useBlockEditor";

import { TextMenu } from "./components/menus/TextMenu";
import { ContentItemMenu } from "./components/menus/ContentItemMenu";
import { SideMenuControls } from "./components/side-menu";
import { ColumnsMenu } from "./extensions/MultiColumn/menus";

const Tiptap = ({
  toolbarHeight,
  initialData,
  onChange,
  onBlur,
}: {
  toolbarHeight: number;
  initialData?: Content;
  onChange: (json: any) => void;
  onBlur: () => void;
}) => {
  const { editor } = useBlockEditor({
    onChange,
    onBlur,
  });

  useEffect(() => {
    const content: Content = initialData ?? [];

    if (content.length === 0 && !editor.isEmpty) {
      editor.commands.clearContent();
    } else if (content.length > 0) {
      editor.commands.setContent(content);
    }
  }, [initialData, editor.isEmpty, editor.commands]);

  return (
    <div className="tiptap-wrapper flex flex-row" style={{ height: `calc(100vh - ${toolbarHeight}px)` }}>
      <TipTapEditor editor={editor} />
      <SideMenuControls editor={editor} />
    </div>
  );
};

/**
 * Isolated instance of the editor to avoid re-rendering other components on changes
 * @param param0
 * @returns
 */
const TipTapEditor = ({ editor }: _TipTapEditorProps) => {
  const menuContainerRef = useRef(null);

  return (
    <div className="flex-1 pt-6 pb-6 overflow-auto classy-scrollbar" ref={menuContainerRef}>
      <EditorContent editor={editor} />
      <ContentItemMenu editor={editor} />
      <LinkMenu editor={editor} appendTo={menuContainerRef} />
      <TextMenu editor={editor} />
      <ColumnsMenu editor={editor} appendTo={menuContainerRef} />
      {/* 
      <TableRowMenu editor={editor} appendTo={menuContainerRef} />
      <TableColumnMenu editor={editor} appendTo={menuContainerRef} />
      <ImageBlockMenu editor={editor} appendTo={menuContainerRef} />
      <HorizontaslLineBlockMenu editor={editor} appendTo={menuContainerRef} />
      <SpacerBlockMenu editor={editor} appendTo={menuContainerRef} />
      <PageBreakBlockMenu editor={editor} appendTo={menuContainerRef} /> */}
    </div>
  );
};

interface _TipTapEditorProps {
  editor: Editor;
}

export default Tiptap;
