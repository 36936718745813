import "./preview.scss";
import { SettingsKey } from "models";
import { useAppSelector } from "utils/store";

const Preview = () => {
  const options = useAppSelector((state) => state.templateBuilderSlice.defaultOptions);

  const showPhotoMetadata = options[SettingsKey.ReportShowDefectPhotoMeta]?.value ?? true;

  return (
    <>
      <div className="list-header"></div>

      <div className="dfl-wrapper">
        {options.reportShowDefectOrientation?.value === "standard" && (
          <div className="dfl-photos flex flex-row">
            <div className="dfl-photo">
              {showPhotoMetadata && <div className="dfl-skeleton"></div>}
              <svg xmlns="http://www.w3.org/2000/svg" height="300" width="200" className="dfl-svg"></svg>
            </div>
            <div className="dfl-photo">
              {showPhotoMetadata && <div className="dfl-skeleton"></div>}
              <svg xmlns="http://www.w3.org/2000/svg" height="300" width="200" className="dfl-svg"></svg>
            </div>
            <div className="dfl-photo">
              {showPhotoMetadata && <div className="dfl-skeleton"></div>}
              <svg xmlns="http://www.w3.org/2000/svg" height="300" width="200" className="dfl-svg"></svg>
            </div>
          </div>
        )}

        <div className="flex flex-row">
          <div className="dfl-block flex-1">
            {options.reportShowDefectNumber?.value && (
              <div className="dfl-item">
                <p>Number</p>
                <div className="dfl-skeleton"></div>
              </div>
            )}

            {options.reportShowDefectArea?.value && (
              <div className="dfl-item">
                <p>Area</p>
                <div className="dfl-skeleton"></div>
              </div>
            )}

            {options.reportShowDefectElement?.value && (
              <div className="dfl-item">
                <p>Element</p>
                <div className="dfl-skeleton"></div>
              </div>
            )}

            {options.reportShowDefectIssue?.value && (
              <div className="dfl-item">
                <p>Issue</p>
                <div className="dfl-skeleton"></div>
              </div>
            )}

            {options.reportShowDefectOrientation?.value === "inline" && (
              <div>
                {options.reportShowDefectStatus?.value && (
                  <div className="dfl-item">
                    <p>Status</p>
                    <div className="dfl-skeleton"></div>
                  </div>
                )}

                {options.reportShowDefectPriority?.value && (
                  <div className="dfl-item">
                    <p>Priority</p>
                    <div className="dfl-skeleton"></div>
                  </div>
                )}

                {options.reportShowDefectDuedate?.value && (
                  <div className="dfl-item">
                    <p>Due Date</p>
                    <div className="dfl-skeleton"></div>
                  </div>
                )}
              </div>
            )}
          </div>

          {options.reportShowDefectOrientation?.value !== "inline" && (
            <div className="dfl-block flex-1">
              {options.reportShowDefectStatus?.value && (
                <div className="dfl-item">
                  <p>Status</p>
                  <div className="dfl-skeleton"></div>
                </div>
              )}

              {options.reportShowDefectPriority?.value && (
                <div className="dfl-item">
                  <p>Priority</p>
                  <div className="dfl-skeleton"></div>
                </div>
              )}
              {options.reportShowDefectDuedate?.value && (
                <div className="dfl-item">
                  <p>Due Date</p>
                  <div className="dfl-skeleton"></div>
                </div>
              )}
            </div>
          )}

          {options.reportShowDefectOrientation?.value === "inline" && (
            <div className="dfl-inline-photo mr-1">
              {showPhotoMetadata && <div className="dfl-skeleton"></div>}
              <svg xmlns="http://www.w3.org/2000/svg" height="300" width="200" className="dfl-svg"></svg>
            </div>
          )}

          {options.reportShowDefectOrientation?.value === "inline" && (
            <div className="dfl-inline-photo">
              {showPhotoMetadata && <div className="dfl-skeleton"></div>}
              <svg xmlns="http://www.w3.org/2000/svg" height="300" width="200" className="dfl-svg"></svg>
            </div>
          )}
        </div>

        <div className="w-full dfl-block">
          {options.reportShowDefectComments?.value && (
            <div>
              <div className="dfl-item">
                <p>Details</p>
                <div className="dfl-skeleton" style={{ width: "calc(100% - 7rem)" }}></div>
              </div>
              <div className="dfl-item">
                <p>&nbsp;</p>
                <div className="dfl-skeleton" style={{ width: "calc(100% - 7rem)" }}></div>
              </div>
            </div>
          )}

          {options.reportShowDefectAssignment?.value && (
            <div className="mt-2">
              <div className="dfl-item">
                <p>Assigned to</p>
                <div className="dfl-skeleton" style={{ width: "calc(100% - 7rem)" }}></div>
              </div>
            </div>
          )}
        </div>

        {options.reportShowDefectOrientation?.value === "below" && (
          <div className="mt-2 dfl-photos flex flex-row">
            <div className="dfl-photo">
              {showPhotoMetadata && <div className="dfl-skeleton"></div>}
              <svg xmlns="http://www.w3.org/2000/svg" height="300" width="200" className="dfl-svg"></svg>
            </div>

            <div className="dfl-photo">
              {showPhotoMetadata && <div className="dfl-skeleton"></div>}
              <svg xmlns="http://www.w3.org/2000/svg" height="300" width="200" className="dfl-svg"></svg>
            </div>

            <div className="dfl-photo">
              {showPhotoMetadata && <div className="dfl-skeleton"></div>}
              <svg xmlns="http://www.w3.org/2000/svg" height="300" width="200" className="dfl-svg"></svg>
            </div>
          </div>
        )}

        {options.reportShowDefectActivity?.value && (
          <div className="mt-3">
            <div className="w-full dfl-block">
              <div className="dfl-item w-full">
                <p>Recent Activity</p>
              </div>
              <div className="dfl-item w-full">
                <div className="dfl-skeleton"></div>
              </div>
              <div className="dfl-item w-full">
                <div className="dfl-skeleton"></div>
              </div>
              <div className="dfl-item w-full">
                <div className="dfl-skeleton"></div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Preview;
