import { ParsedQuery } from "query-string";
import axios, { InternalAxiosRequestConfig } from "axios";
import { environment } from "environments/environment";

/**
 * Set up method to get query params and
 * set them into the session management of the browser window
 * @param {ParsedQuery} queryParams Query Params object parsed via query-string npm
 */
const setSession = (queryParams: ParsedQuery) => {
  const token = queryParams.token ?? "";
  bindApiHeader({
    token: `${token}`,
  });
};

/**
 * Sub-method to set request headers required for all HTTP calls
 */
const bindApiHeader = ({ token }: { token: string }) => {
  axios.interceptors.request.use(
    (request: InternalAxiosRequestConfig<any>) => {
      request.headers["Content-Type"] = request.headers["Content-Type"] || "application/json";
      request.headers["Ocp-Apim-Subscription-Key"] = environment.apiSubscriptionKey;
      request.headers["Ocp-Apim-Trace"] = "false";
      request.headers.token = token;
      return request;
    },
    (err) => err
  );
};

export default setSession;
