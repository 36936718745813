import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Slider } from "primereact/slider";
import { Button } from "primereact/button";

import { Icon } from "..";

const MemoButton = memo(Button);

export const SliderInput = memo(({ onChange, value, minValue = 10, maxValue = 100 }: SliderInputProps) => {
  const [currentValue, setCurrentValue] = useState(value);
  const intervalRef = useRef<NodeJS.Timer | null>(null);

  const onChangeCallback = useCallback(() => onChange(currentValue), [currentValue, onChange]);

  const initValue = useMemo(() => {
    if (value !== currentValue) {
      return value;
    }
    return currentValue;
  }, [value, currentValue]);

  useEffect(() => {
    // sets init value when selecting new block
    setCurrentValue(initValue);
  }, [initValue]);

  useEffect(() => {
    // when App is unmounted we should stop counter
    return () => stopCounter();
  }, []);

  // trigger the callback after currentValue changes
  useEffect(() => {
    onChangeCallback();
  }, [currentValue, onChangeCallback]);

  const onSliderChange = (value: number | [number, number]) => {
    try {
      const nextValue = Number(value);
      setCurrentValue((_) => Math.min(maxValue, Math.max(minValue, nextValue)));
    } catch (ex) {
      console.error(ex);
    }
  };

  const startCounter = (direction: number) => {
    if (intervalRef.current) return;
    intervalRef.current = setInterval(() => {
      setCurrentValue((currentValue) => Math.min(maxValue, Math.max(minValue, currentValue + direction)));
    }, 75);
  };

  const stopCounter = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  return (
    <div className="flex items-center">
      <MemoButton
        className="pl-3 pr-3 mr-1 p-button-secondary"
        text
        onMouseDown={() => startCounter(-1)}
        onMouseUp={stopCounter}
        onMouseLeave={stopCounter}
        children={<Icon name="Minus" size={18} />}
      />
      <div className="flex-1">
        <Slider value={currentValue} onChange={(e) => onSliderChange(e.value)} min={minValue} max={maxValue} step={1} />
      </div>
      <MemoButton
        className="pl-3 pr-3 ml-1 p-button-secondary"
        text
        onMouseDown={() => startCounter(1)}
        onMouseUp={stopCounter}
        onMouseLeave={stopCounter}
        children={<Icon name="Plus" size={18} />}
      />
    </div>
  );
});

interface SliderInputProps {
  onChange: (value: number) => void;
  value: number;
  minValue?: number;
  maxValue?: number;
}

SliderInput.displayName = "SliderInput";
