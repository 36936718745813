export const environment_dev = {
  production: false,
  /** Whether the save and updates are enabled */
  enabled: true,
  apiBaseUrl: "https://defectwise-api-apim.azure-api.net/defectwise-api-dev/v1",
  apiSubscriptionKey: "26740c61a0204358853f5f57f75a4749",
  reportServerUrl:
    "https://defectwise-report-api-dev.agreeableriver-0160f87d.australiaeast.azurecontainerapps.io/api/v1",
  // reportServerUrl: "http://localhost:3100/api/v1",
  reportSubscriptionKey: "d4f525d47c024256882a1421977e1a21",
};
