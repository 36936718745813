import { useEffect, useMemo, useState } from "react";

import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";

import { ContentTypePickerCategory, ContentTypePickerOption, ContentTypePickerProps } from "./ContentTypePicker";
import { Icon } from "utils/ui";
import { cn } from "utils/helpers";

const isOption = (option: ContentTypePickerOption | ContentTypePickerCategory): option is ContentTypePickerOption =>
  option.type === "option";

export const ContentTypeDropdown = ({ options }: ContentTypePickerProps) => {
  const [selectedValue, setSelectedValue] = useState<string>();

  const activeItem: ContentTypePickerOption | undefined = useMemo(
    () => options.find((option) => option.type === "option" && option.isActive()),
    [options]
  );

  const dropdownOptions = useMemo(() => {
    return [
      ...options
        .map((option) => {
          if (isOption(option)) {
            return {
              label: option.label,
              id: option.id,
              option: option,
            };
          }
          return null;
        })
        .filter((option) => option !== null),
    ];
  }, [options]);

  const isActive = useMemo(() => {
    return (
      activeItem?.id === "paragraph" ||
      activeItem?.id === "heading1" ||
      activeItem?.id === "heading2" ||
      activeItem?.id === "heading3" ||
      activeItem?.id === "orderedList" ||
      activeItem?.id === "bulletList"
    );
  }, [activeItem]);

  useEffect(() => {
    try {
      if (activeItem?.id) {
        setSelectedValue(activeItem.id);
      }
    } catch (ex) {
      console.error(ex);
    }
  }, [activeItem]);

  const onChange = (e: DropdownChangeEvent) => {
    // find the option that matches the id
    try {
      const option = options.find((option) => option.id === e.value);
      if (option) {
        option.onClick();
      }
    } catch (ex) {
      console.error(ex);
    }
  };

  return (
    <Dropdown
      value={selectedValue}
      onChange={(e) => {
        onChange(e);
        setSelectedValue(e.value);
      }}
      options={dropdownOptions}
      optionLabel="label"
      optionValue="id"
      placeholder="Select a Text Type"
      className="w-full md:w-14rem"
      disabled={!isActive}
      itemTemplate={({ id, label, option }) => {
        return (
          <div
            key={id}
            className={cn("flex flex-row items-center", option.isActive() ? "bg-primary-400" : "text-gray-900")}
          >
            <Icon name={option.icon} className="w-4 h-4 mr-1" />
            <span className="ml-2">{label}</span>
          </div>
        );
      }}
    />
  );
};
