import { ButtonHTMLAttributes, HTMLProps, forwardRef } from "react";

import { cn } from "utils/helpers";
import { Surface } from "./Surface";
import { Button, ButtonProps } from "./Button";
import Tooltip from "./Tooltip";

export type ToolbarWrapperProps = {
  title?: string;
  shouldShowContent?: boolean;
  isVertical?: boolean;
} & HTMLProps<HTMLDivElement>;

const ToolbarWrapper = forwardRef<HTMLDivElement, ToolbarWrapperProps>(
  ({ title, shouldShowContent = true, children, isVertical = false, className, ...rest }, ref) => {
    const toolbarClassName = cn(
      "styled-toolbar-wrapper text-black h-full leading-none gap-0-5",
      isVertical ? "flex-col p-2" : "flex-row items-center",
      className
    );

    const titleClassName = cn(
      "p-2 styled-toolbar-title text-neutral-500 text-[0.8rem] uppercase font-semibold cursor-default select-none"
    );

    return (
      shouldShowContent && (
        <Surface className={toolbarClassName} {...rest} ref={ref}>
          {title && <div className={titleClassName}>{title}</div>}
          <div className="p-1 inline-flex">{children}</div>
        </Surface>
      )
    );
  }
);

ToolbarWrapper.displayName = "Toolbar";

export type ToolbarDividerProps = {
  horizontal?: boolean;
} & HTMLProps<HTMLDivElement>;

const ToolbarDivider = forwardRef<HTMLDivElement, ToolbarDividerProps>(({ horizontal, className, ...rest }, ref) => {
  let style = horizontal ? { minWidth: "1.5rem", height: "1px" } : { width: "1px", minHeight: "26px" };
  // let classes = "";
  const dividerClassName = cn(
    "bg-neutral-200 dark:bg-neutral-800",
    horizontal ? "w-full my-1 first:mt-0 last:mt-0" : "h-full mx-1 first:ml-0 last:mr-0",
    className
  );

  return <div style={style} className={dividerClassName} ref={ref} {...rest} />;
});

ToolbarDivider.displayName = "Toolbar.Divider";

export type ToolbarButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  active?: boolean;
  activeClassname?: string;
  tooltip?: string;
  tooltipShortcut?: string[];
  buttonSize?: ButtonProps["buttonSize"];
  variant?: ButtonProps["variant"];
};

const ToolbarButton = forwardRef<HTMLButtonElement, ToolbarButtonProps>(
  (
    { children, buttonSize = "icon", variant = "ghost", className, tooltip, tooltipShortcut, activeClassname, ...rest },
    ref
  ) => {
    const buttonClass = cn("gap-1 px-2 w-auto", className);

    const content = (
      <Button
        activeClassname={activeClassname}
        style={{ minWidth: "2rem" }}
        className={buttonClass}
        variant={variant}
        buttonSize={buttonSize}
        ref={ref}
        {...rest}
      >
        {children}
      </Button>
    );

    if (tooltip) {
      return (
        <Tooltip title={tooltip} shortcut={tooltipShortcut}>
          {content}
        </Tooltip>
      );
    }

    return content;
  }
);

ToolbarButton.displayName = "ToolbarButton";

export const Toolbar = {
  Wrapper: ToolbarWrapper,
  Divider: ToolbarDivider,
  Button: ToolbarButton,
};
