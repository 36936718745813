import { useMemo } from "react";
import { Editor } from "@tiptap/react";

import { Plus } from "lucide-react";
import { Button } from "primereact/button";

import { SidebarMenu } from "utils/ui";
import FIELD_TOKENS from "pages/tip-tap-editor/extensions/FieldToken/groups";

export const FieldTokenMenu = ({ editor }: { editor: Editor }) => {
  const tokens = useMemo(() => {
    return FIELD_TOKENS.sort((a, b) => a.label.localeCompare(b.label));
  }, []);

  return (
    <SidebarMenu.Wrapper title="Fields">
      <SidebarMenu.Heading title="Insert Fields" />
      <p>Insert fields into your report to automatically populate information.</p>
      <p>Try typing "@" followed by the name of the field you want to add or select from the list below.</p>

      {tokens.map((e) => {
        return (
          <Button
            key={`btn-${e.id}`}
            className="w-full p-button-secondary"
            text
            title={e.description}
            onClick={() => {
              editor.commands.insertContent({
                type: "mention",
                attrs: {
                  id: e.id,
                  label: e.label,
                },
              });
            }}
          >
            <div className="flex flex-row items-center">
              <Plus size={16} />
              <span className="ml-2">{e.label}</span>
            </div>
          </Button>
        );
      })}

      <SidebarMenu.Divider />
    </SidebarMenu.Wrapper>
  );
};
