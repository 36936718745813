import { mergeAttributes, Node, NodeViewProps } from "@tiptap/core";
import { NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react";

// https://tiptap.dev/docs/editor/extensions/custom-extensions/node-views/react

export interface PageBreakBlockOptions {
  HTMLAttributes: Record<string, any>;
  color: string;
  height: number;
  width: number;
  alignment: "left" | "center" | "right";
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    PageBreak: {
      setPageBreakBlock: () => ReturnType;
    };
  }
}

const HorizontalLineComponent = ({ node }: NodeViewProps) => {
  return (
    <NodeViewWrapper className="block-page-break">
      <div className="page-break-line flex-1"></div>

      <div className="page-break-text pl-1 pr-1" style={{ minHeight: "22px" }}>
        {"[ PAGE BREAK ]"}
      </div>

      <div className="page-break-line flex-1"></div>
    </NodeViewWrapper>
  );
};

export const PageBreakBlock = Node.create<PageBreakBlockOptions>({
  name: "pageBreak",

  group: "block",

  content: "",

  selectable: true,

  // this for, @Mentions, project photo, not text stuff
  atom: true,

  addAttributes() {
    return {};
  },

  renderHTML({ HTMLAttributes }) {
    return ["div", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(HorizontalLineComponent);
  },

  addCommands() {
    return {
      setPageBreakBlock:
        () =>
        ({ commands }) => {
          return commands.insertContent({ type: "pageBreak" });
        },
    };
  },
});
