import { memo } from "react";
import { Editor } from "@tiptap/react";

import { ButtonGroup } from "primereact/buttongroup";

import { useTextmenuContentTypes } from "./hooks/useTextmenuContentTypes";
import { useTextmenuCommands } from "./hooks/useTextmenuCommands";
import { useTextmenuStates } from "./hooks/useTextmenuStates";

import { Icon, SidebarMenu } from "utils/ui";

import { ContentTypeDropdown } from "./components/ContentTypeDropdown";

// We memorize the button so each button is not rerendered
// on every editor state change
const MemoButton = memo(SidebarMenu.Button);
const MemoContentTypePicker = memo(ContentTypeDropdown);

export const SidebarTextMenu = ({ editor }: SidebarTextMenuProps) => {
  const commands = useTextmenuCommands(editor);
  const states = useTextmenuStates(editor);
  const blockOptions = useTextmenuContentTypes(editor);

  return (
    <SidebarMenu.Wrapper title="Text">
      <SidebarMenu.Heading title="Content Type" />
      <MemoContentTypePicker options={blockOptions} />

      <SidebarMenu.Divider />
      <SidebarMenu.Heading title="Style" />

      <MemoButton
        tooltip="Bold"
        className="mr-2"
        tooltipShortcut={["Mod", "B"]}
        onClick={commands.onBold}
        active={states.isBold}
      >
        <Icon name="Bold" />
      </MemoButton>
      <MemoButton
        tooltip="Italic"
        className="mr-2"
        tooltipShortcut={["Mod", "I"]}
        onClick={commands.onItalic}
        active={states.isItalic}
      >
        <Icon name="Italic" />
      </MemoButton>
      <MemoButton
        tooltip="Underline"
        tooltipShortcut={["Mod", "U"]}
        onClick={commands.onUnderline}
        active={states.isUnderline}
      >
        <Icon name="Underline" />
      </MemoButton>

      <SidebarMenu.Divider />
      <SidebarMenu.Heading title="Alignment" />

      <ButtonGroup>
        <SidebarMenu.Button
          tooltip="Align left"
          tooltipShortcut={["Shift", "Mod", "L"]}
          onClick={commands.onAlignLeft}
          active={states.isAlignLeft}
          children={<Icon name="AlignLeft" />}
        />
        <SidebarMenu.Button
          tooltip="Align center"
          tooltipShortcut={["Shift", "Mod", "E"]}
          onClick={commands.onAlignCenter}
          active={states.isAlignCenter}
          children={<Icon name="AlignCenter" />}
        />
        <SidebarMenu.Button
          tooltip="Align right"
          tooltipShortcut={["Shift", "Mod", "R"]}
          onClick={commands.onAlignRight}
          active={states.isAlignRight}
          children={<Icon name="AlignRight" />}
        />
      </ButtonGroup>

      <SidebarMenu.Divider />
      <SidebarMenu.Heading title="Text Color" />

      <SidebarMenu.ColorPicker
        currentColor={states.currentColor}
        onChangeColor={commands.onChangeColor}
        onClearColor={commands.onClearColor}
      />
    </SidebarMenu.Wrapper>
  );
};

interface SidebarTextMenuProps {
  editor: Editor;
}
