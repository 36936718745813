import { useDebouncedCallback } from "use-debounce";

import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";

import OptionsInputSwitch from "./input-switch";
import { SidebarMenu } from "utils/ui";
import { saveTemplate } from "utils/api";
import { useAppSelector, useAppDispatch, setOption } from "utils/store";
import { SettingsKey, kToolbarHeight } from "models";

const SideMenu = () => {
  const dispatch = useAppDispatch();
  const options = useAppSelector((state) => state.templateBuilderSlice.defaultOptions);
  const disableOptions = useAppSelector((state) => state.templateBuilderSlice.loading);

  const doDebouncedSave = useDebouncedCallback(async () => dispatch(saveTemplate()), 3000, {
    leading: false,
    trailing: true,
  });

  const selectOptions: { value: string; label: string }[] = [
    { value: "standard", label: "1. Standard" },
    { value: "inline", label: "2. Inline" },
    { value: "below", label: "3. Below" },
    { value: "no-photos", label: "4. Hide Photos" },
  ];

  const toggleSelect = ({ value }: { value: string }, settingsKey: SettingsKey) => {
    dispatch(
      setOption({
        key: settingsKey,
        option: { ...options[settingsKey], value } as any,
      })
    );

    doDebouncedSave();
  };

  if (options === undefined) return null;

  return (
    <SidebarMenu.Wrapper title="Defect Lists" style={{ height: `calc(100vh - 60px - ${kToolbarHeight}px)` }}>
      <SidebarMenu.Heading title="Photos" />

      <div className="flex flex-row justify-content-between items-center">
        <div className="flex-1">
          Photo Grid Orientation
          <br />
          {!disableOptions &&
            (options[SettingsKey.ReportShowDefectOrientation]?.value === "standard" ||
              options[SettingsKey.ReportShowDefectOrientation]?.value === "below") && (
              <span className="text-sm">Max 3 Images</span>
            )}
          {!disableOptions && options[SettingsKey.ReportShowDefectOrientation]?.value === "inline" && (
            <span className="text-sm">Max 2 Images</span>
          )}
        </div>
        <Dropdown
          options={selectOptions}
          value={options[SettingsKey.ReportShowDefectOrientation]?.value ?? "standard"}
          optionLabel="label"
          optionValue="value"
          disabled={disableOptions}
          onChange={(ev) => toggleSelect(ev, SettingsKey.ReportShowDefectOrientation)}
        />
      </div>
      <SidebarMenu.Divider />

      <OptionsInputSwitch
        label="Show Photo Metadata"
        settingsKey={SettingsKey.ReportShowDefectPhotoMeta}
        options={options}
        disabled={disableOptions}
        onChange={doDebouncedSave}
      />

      <SidebarMenu.Divider />

      <SidebarMenu.Heading title="Defect Details" />

      <p>Show or hide elements on defect report pages.</p>
      <SidebarMenu.Divider />

      <OptionsInputSwitch
        label="Defect Number"
        settingsKey={SettingsKey.ReportShowDefectNumber}
        options={options}
        disabled={disableOptions}
        onChange={doDebouncedSave}
      />
      <SidebarMenu.Divider />

      <OptionsInputSwitch
        label="Area Description"
        settingsKey={SettingsKey.ReportShowDefectArea}
        options={options}
        disabled={disableOptions}
        onChange={doDebouncedSave}
      />
      <SidebarMenu.Divider />

      <OptionsInputSwitch
        label="Element Description"
        settingsKey={SettingsKey.ReportShowDefectElement}
        options={options}
        disabled={disableOptions}
        onChange={doDebouncedSave}
      />
      <SidebarMenu.Divider />

      <OptionsInputSwitch
        label="Issue Description"
        settingsKey={SettingsKey.ReportShowDefectIssue}
        options={options}
        disabled={disableOptions}
        onChange={doDebouncedSave}
      />
      <SidebarMenu.Divider />

      <OptionsInputSwitch
        label="Defect Status"
        settingsKey={SettingsKey.ReportShowDefectStatus}
        options={options}
        disabled={disableOptions}
        onChange={doDebouncedSave}
      />
      <SidebarMenu.Divider />

      <OptionsInputSwitch
        label="Defect Priority"
        settingsKey={SettingsKey.ReportShowDefectPriority}
        options={options}
        disabled={disableOptions}
        onChange={doDebouncedSave}
      />
      <SidebarMenu.Divider />

      <OptionsInputSwitch
        label="Due Date"
        settingsKey={SettingsKey.ReportShowDefectDuedate}
        options={options}
        disabled={disableOptions}
        onChange={doDebouncedSave}
      />
      <Divider className="mt-3 mb-3" type="solid" />

      <OptionsInputSwitch
        label="Details"
        settingsKey={SettingsKey.ReportShowDefectComments}
        options={options}
        disabled={disableOptions}
        onChange={doDebouncedSave}
      />
      <SidebarMenu.Divider />

      <OptionsInputSwitch
        label="Assigned To"
        settingsKey={SettingsKey.ReportShowDefectAssignment}
        options={options}
        disabled={disableOptions}
        onChange={doDebouncedSave}
      />
      <SidebarMenu.Divider />

      <SidebarMenu.Heading title="Activity" />

      <OptionsInputSwitch
        label="Comments / Recent Activity"
        settingsKey={SettingsKey.ReportShowDefectActivity}
        options={options}
        disabled={disableOptions}
        onChange={doDebouncedSave}
      />
      <SidebarMenu.Divider />
    </SidebarMenu.Wrapper>
  );
};

export default SideMenu;
