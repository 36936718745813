import { Content } from "@tiptap/react";
import { kImageLogoPlaceholder, kImageProjectPlaceholder } from "./constants";

export const defaultPageHeader: Content = [
  {
    type: "paragraph",
    attrs: {
      textAlign: "left",
    },
    content: [
      {
        type: "mention",
        attrs: {
          id: "projectName",
          label: "Project Name",
        },
        marks: [
          {
            type: "bold",
          },
        ],
      },
      {
        type: "text",
        marks: [
          {
            type: "bold",
          },
        ],
        text: " ",
      },
      {
        type: "mention",
        attrs: {
          id: "projectRef",
          label: "Project Ref",
        },
        marks: [
          {
            type: "bold",
          },
        ],
      },
    ],
  },
  {
    type: "paragraph",
    attrs: {
      textAlign: "left",
    },
    content: [
      {
        type: "mention",
        attrs: {
          id: "projectAddress",
          label: "Project Address",
        },
      },
    ],
  },
];

export const defaultPageFooter: Content = [
  {
    type: "paragraph",
    attrs: {
      textAlign: "left",
    },
    content: [
      {
        type: "mention",
        attrs: {
          id: "createDate",
          label: "Create Date",
        },
      },
    ],
  },
];

export const defaultCoverPage: Content = [
  {
    type: "columns",
    attrs: {
      layout: "sidebar-right",
    },
    content: [
      {
        type: "column",
        attrs: {
          position: "left",
        },
        content: [
          {
            type: "paragraph",
            attrs: {
              textAlign: "left",
            },
          },
        ],
      },
      {
        type: "column",
        attrs: {
          position: "right",
        },
        content: [
          {
            type: "imageBlock",
            attrs: {
              src: kImageLogoPlaceholder,
              width: "100%",
              align: "center",
              type: "logo",
            },
          },
          {
            type: "paragraph",
            attrs: {
              textAlign: "center",
            },
            content: [
              {
                type: "mention",
                attrs: {
                  id: "company",
                  label: "Company Name",
                },
                marks: [
                  {
                    type: "bold",
                  },
                ],
              },
              {
                type: "text",
                marks: [
                  {
                    type: "bold",
                  },
                ],
                text: " ",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "heading",
    attrs: {
      textAlign: "left",
      level: 1,
    },
    content: [
      {
        type: "text",
        text: "DEFECT REPORT",
      },
    ],
  },
  {
    type: "horizontalLine",
    attrs: {
      color: "#e0914c",
      height: 5,
      width: 100,
      alignment: "center",
    },
  },
  {
    type: "imageBlock",
    attrs: {
      src: kImageProjectPlaceholder,
      width: "100%",
      align: "center",
      type: "project-image",
    },
  },
  {
    type: "heading",
    attrs: {
      textAlign: "left",
      level: 3,
    },
    content: [
      {
        type: "mention",
        attrs: {
          id: "projectRef",
          label: "Project Ref",
        },
      },
      {
        type: "text",
        text: " - ",
      },
      {
        type: "mention",
        attrs: {
          id: "projectName",
          label: "Project Name",
        },
      },
      {
        type: "text",
        text: "  ",
      },
    ],
  },
  {
    type: "spacerBlock",
    attrs: {
      height: 10,
    },
  },
  {
    type: "paragraph",
    attrs: {
      textAlign: "left",
    },
    content: [
      {
        type: "text",
        marks: [
          {
            type: "bold",
          },
        ],
        text: "INSPECTION ADDRESS:",
      },
    ],
  },
  {
    type: "paragraph",
    attrs: {
      textAlign: "left",
    },
    content: [
      {
        type: "mention",
        attrs: {
          id: "projectAddress",
          label: "Project Address",
        },
      },
      {
        type: "text",
        text: " ",
      },
    ],
  },
  {
    type: "spacerBlock",
    attrs: {
      height: 10,
    },
  },
  {
    type: "paragraph",
    attrs: {
      textAlign: "left",
    },
    content: [
      {
        type: "text",
        marks: [
          {
            type: "bold",
          },
        ],
        text: "PREPARED FOR / ON BEHALF OF:",
      },
    ],
  },
  {
    type: "paragraph",
    attrs: {
      textAlign: "left",
    },
    content: [
      {
        type: "mention",
        attrs: {
          id: "client",
          label: "Project Client",
        },
      },
      {
        type: "text",
        text: " ",
      },
    ],
  },
  {
    type: "spacerBlock",
    attrs: {
      height: 10,
    },
  },
  {
    type: "paragraph",
    attrs: {
      textAlign: "left",
    },
    content: [
      {
        type: "text",
        marks: [
          {
            type: "bold",
          },
        ],
        text: "PREPARED BY:",
      },
    ],
  },
  {
    type: "paragraph",
    attrs: {
      textAlign: "left",
    },
    content: [
      {
        type: "mention",
        attrs: {
          id: "userName",
          label: "Created By User",
        },
      },
      {
        type: "text",
        text: " ",
      },
    ],
  },
  {
    type: "paragraph",
    attrs: {
      textAlign: "left",
    },
    content: [
      {
        type: "mention",
        attrs: {
          id: "userPosition",
          label: "Created By User Position",
        },
      },
      {
        type: "text",
        text: " ",
      },
    ],
  },
  {
    type: "spacerBlock",
    attrs: {
      height: 10,
    },
  },
  {
    type: "paragraph",
    attrs: {
      textAlign: "center",
    },
    content: [
      {
        type: "text",
        marks: [
          {
            type: "bold",
          },
        ],
        text: "DATE: ",
      },
      {
        type: "mention",
        attrs: {
          id: "createDate",
          label: "Create Date",
        },
      },
      {
        type: "text",
        text: " ",
      },
    ],
  },
];
